import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));

const OffersEditor = lazy(() => import('./Offers/editor'));
const LabelsEditor = lazy(() => import('./Products/Labels'));
const LabelsNewUIEditor = lazy(() => import('./Products/LabelsNewUI'));

const CalculatorsEditor = lazy(() => import('./Products/Calculators/Calculators'))
const CalculatorsList = lazy(() => import('./Products/Calculators/List'))

class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/dashboard" component={ Dashboard } />

          <Route exact path="/catalog/offers" component={ OffersEditor } />

          <Route exact path="/catalog/labels" component={ LabelsEditor } />
          <Route exact path="/catalog/labelsNewUI" component={ LabelsNewUIEditor } />

          <Route exact path="/catalog/calculators/" component={ CalculatorsList } />
          <Route exact path="/catalog/calculators/:id" component={ CalculatorsEditor } />


          <Route path="/login" component={ Login } />

          <Route path="/404" component={ Error404 } />
          <Route path="/500" component={ Error500 } />

          <Route path="/basic-ui/buttons" component={ Buttons } />
          <Route path="/basic-ui/dropdowns" component={ Dropdowns } />
          <Route path="/basic-ui/typography" component={ Typography } />

          <Route path="/form-Elements/basic-elements" component={ BasicElements } />

          <Route path="/tables/basic-table" component={ BasicTable } />

          <Route path="/icons/mdi" component={ Mdi } />

          <Route path="/charts/chart-js" component={ ChartJs } />

          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
