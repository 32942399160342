import Cookies from "./Cookies";
import FingerprintJS from '../outerScripts/FP'

const updateAccessToken = {
    initialize: () => {
        let expire = Cookies.getCookies("accessTokenTime");
        if (expire === "")
            return;

        let time_updated = (expire - 60) * 1000;

        let getUID = async () => (await (await FingerprintJS.load()).get()).visitorId;

        let isBlocked = false;

        let updateToken = async () => {
            isBlocked = true;
            try {
                let token = await fetch("https://services.kenguru.ru/auth/refreshToken", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        fingerPrint: await getUID()
                    })
                }).then((res) => {
                    return res.json();
                });

                if (token.accessToken) {
                    let tokenSplit = token.accessToken.split('.');

                    let payload = JSON.parse(atob(tokenSplit[1]));
                    time_updated = (payload.exp - 60) * 1000;
                }
            } catch (e) {

            }
            isBlocked = false;
        }

        let check = () => {
            if (new Date().getTime() < time_updated || isBlocked) {
                return;
            }

            updateToken();
        }

        window.onload = () => {
            check();
        }
        setInterval(check, 1000 * 30);
    }
}

export default updateAccessToken;
