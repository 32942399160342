import React, {Component, useEffect, useState} from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {GlobalState} from "../App";

class Notification extends Component {
    state = {
        notifications: []
    }

    logLevel = {
        "error": "danger",
        "log": "info",
        "warning": "warning",
        "success": "success"
    }

    componentDidMount() {
        GlobalState.getInstance().addEventListener("pushNotification", this.pushNotification.bind(this))
    }

    pushNotification(e) {

        let realCountNotification = 0;
        this.state.notifications.forEach((item, index) => {
            if (item === undefined)
                return;

            realCountNotification++;
        });
        if (realCountNotification === 0) {
            this.setState({
                notifications: [
                    e.detail.notification
                ]
            });
            return;
        }

        this.setState({
            notifications: [
                ...this.state.notifications,
                e.detail.notification
            ]
        })
    }

    removeNotification(index) {
        let notifications = [...this.state.notifications];

        delete notifications[index];

        this.setState({
            notifications
        })
    }

    mapNotifications() {
        let ret = [];

        this.state.notifications.forEach((item, index) => {
            if (item === undefined)
                return;

            ret.push(
                <Toast
                    key={index}
                    bg={this.logLevel[item.level] ?? "info"}
                    animation={true}
                    autohide={true}
                    delay={item.time}
                    onClose={(e) => this.removeNotification(index)}>
                    <Toast.Header closeButton={true}>
                        <strong className="me-auto">Система</strong>
                    </Toast.Header>
                    <Toast.Body>{item.text}</Toast.Body>
                </Toast>
            )
        })

        return ret;
    }

    componentWillUnmount() {
        GlobalState.getInstance().removeEventListener("pushNotification", this.pushNotification.bind(this))
    }

    render () {
        return (
            <ToastContainer className="notification">
                {
                    this.mapNotifications()
                }
            </ToastContainer>
        );
    }
}

export default Notification;
