import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Dropdown } from 'react-bootstrap';
import fullLogo from '../../assets/images/logo.svg';
import miniLogo from '../../assets/images/logo-mini.svg';
import {GlobalState} from "../App";

class Sidebar extends Component {

  state = {};

  dropdownPaths = [
    {path:'/catalog', state: 'catalogMenuOpen'}
    // {path:'/apps', state: 'appsMenuOpen'},
    // {path:'/basic-ui', state: 'basicUiMenuOpen'},
    // {path:'/form-elements', state: 'formElementsMenuOpen'},
    // {path:'/tables', state: 'tablesMenuOpen'},
    // {path:'/icons', state: 'iconsMenuOpen'},
    // {path:'/charts', state: 'chartsMenuOpen'},
    // {path:'/user-pages', state: 'userPagesMenuOpen'},
    // {path:'/error-pages', state: 'errorPagesMenuOpen'},
  ];

  toggleMenuState(menuState) {
    let state = this.state;

    this.dropdownPaths.forEach(item => {
      state[item.state] = false;
    });
    state[menuState] = true;

    this.setState(state);
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      userInfo: GlobalState.getInstance().getState("user")
    })
    GlobalState.getInstance().addEventListener("changeState_user", this.updateUserInfo.bind(this))
  }

  updateUserInfo(e) {
    this.setState({
      ...this.state,
      userInfo: e.detail.data
    })
  }

  componentWillUnmount() {
    GlobalState.getInstance().removeEventListener("changeState_user", this.updateUserInfo.bind(this))
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    let state = this.state;

    this.dropdownPaths.forEach(item => {
      state[item.state] = false;
    });

    this.dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        state[obj.state] = true;
      }
    }));

    this.setState(state);

  }

  render () {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="/"><img src={fullLogo} alt="logo" /></a>
          <a className="sidebar-brand brand-logo-mini" href="/"><img src={miniLogo} alt="logo" /></a>
        </div>
        <ul className="nav">
          <li className="nav-item profile">
            <div className="profile-desc">
              <div className="profile-pic">
                <div className="count-indicator">
                  <img className="img-xs rounded-circle " src={require('../../assets/images/faces-clipart/pic-4.png')} alt="profile" />
                  <span className="count bg-success"></span>
                </div>
                <div className="profile-name">
                  <h5 className="mb-0 font-weight-normal">{this.state.userInfo?.name ?? "Загрузка..."}</h5>
                  {/*<span>Gold Member</span>*/}
                </div>
              </div>
              {/*<Dropdown alignright="true">*/}
              {/*  <Dropdown.Toggle as="a" className="cursor-pointer no-caret">*/}
              {/*    <i className="mdi mdi-dots-vertical"></i>*/}
              {/*  </Dropdown.Toggle>*/}
              {/*  <Dropdown.Menu className="sidebar-dropdown preview-list">*/}
              {/*    <a href="!#" className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>*/}
              {/*      <div className="preview-thumbnail">*/}
              {/*        <div className="preview-icon bg-dark rounded-circle">*/}
              {/*          <i className="mdi mdi-settings text-primary"></i>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="preview-item-content">*/}
              {/*        <p className="preview-subject ellipsis mb-1 text-small">Account settings</p>*/}
              {/*      </div>*/}
              {/*    </a>*/}
              {/*    <div className="dropdown-divider"></div>*/}
              {/*    <a href="!#" className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>*/}
              {/*      <div className="preview-thumbnail">*/}
              {/*        <div className="preview-icon bg-dark rounded-circle">*/}
              {/*          <i className="mdi mdi-onepassword  text-info"></i>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="preview-item-content">*/}
              {/*        <p className="preview-subject ellipsis mb-1 text-small">Change Password</p>*/}
              {/*      </div>*/}
              {/*    </a>*/}
              {/*    <div className="dropdown-divider"></div>*/}
              {/*    <a href="!#" className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>*/}
              {/*      <div className="preview-thumbnail">*/}
              {/*        <div className="preview-icon bg-dark rounded-circle">*/}
              {/*          <i className="mdi mdi-calendar-today text-success"></i>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="preview-item-content">*/}
              {/*        <p className="preview-subject ellipsis mb-1 text-small">To-do list</p>*/}
              {/*      </div>*/}
              {/*    </a>*/}
              {/*  </Dropdown.Menu>*/}
              {/*</Dropdown>*/}
            </div>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link">Навигация</span>
          </li>
          <li className={ this.isPathActive('/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
              <span className="menu-title">Главная</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/catalog') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ this.state.catalogMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('catalogMenuOpen') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-laptop"></i>
              </span>
              <span className="menu-title">Каталог</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.catalogMenuOpen }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ this.isPathActive('/catalog/offers') ? 'nav-link active' : 'nav-link' } to="/catalog/offers">Торговые предложения</Link></li>
                </ul>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ this.isPathActive('/catalog/labels') ? 'nav-link active' : 'nav-link' } to="/catalog/labels">Товары на главной</Link></li>
                </ul>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ this.isPathActive('/catalog/LabelsNewUI') ? 'nav-link active' : 'nav-link' } to="/catalog/LabelsNewUI">Товары на главной NEW</Link></li>
                </ul>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ this.isPathActive('/catalog/calculators') ? 'nav-link active' : 'nav-link' } to="/catalog/calculators">Калькуляторы</Link></li>
                </ul>
              </div>
            </Collapse>
          </li>

          {/*<li className={ this.isPathActive('/basic-ui') ? 'nav-item menu-items active' : 'nav-item menu-items' }>*/}
          {/*  <div className={ this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">*/}
          {/*    <span className="menu-icon">*/}
          {/*      <i className="mdi mdi-laptop"></i>*/}
          {/*    </span>*/}
          {/*    <span className="menu-title">Basic UI Elements</span>*/}
          {/*    <i className="menu-arrow"></i>*/}
          {/*  </div>*/}
          {/*  <Collapse in={ this.state.basicUiMenuOpen }>*/}
          {/*    <div>*/}
          {/*      <ul className="nav flex-column sub-menu">*/}
          {/*        <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link' } to="/basic-ui/buttons">Buttons</Link></li>*/}
          {/*        <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link' } to="/basic-ui/dropdowns">Dropdowns</Link></li>*/}
          {/*        <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/typography') ? 'nav-link active' : 'nav-link' } to="/basic-ui/typography">Typography</Link></li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </Collapse>*/}
          {/*</li>*/}
          {/*<li className={ this.isPathActive('/form-elements') ? 'nav-item menu-items active' : 'nav-item menu-items' }>*/}
          {/*  <div className={ this.state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('formElementsMenuOpen') } data-toggle="collapse">*/}
          {/*    <span className="menu-icon">*/}
          {/*      <i className="mdi mdi-playlist-play"></i>*/}
          {/*    </span>*/}
          {/*    <span className="menu-title">Form Elements</span>*/}
          {/*    <i className="menu-arrow"></i>*/}
          {/*  </div>*/}
          {/*  <Collapse in={ this.state.formElementsMenuOpen }>*/}
          {/*    <div>*/}
          {/*      <ul className="nav flex-column sub-menu">*/}
          {/*        <li className="nav-item"> <Link className={ this.isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link' } to="/form-elements/basic-elements">Basic Elements</Link></li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </Collapse>*/}
          {/*</li>*/}
          {/*<li className={ this.isPathActive('/tables') ? 'nav-item menu-items active' : 'nav-item menu-items' }>*/}
          {/*  <div className={ this.state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('tablesMenuOpen') } data-toggle="collapse">*/}
          {/*    <span className="menu-icon">*/}
          {/*      <i className="mdi mdi-table-large"></i>*/}
          {/*    </span>*/}
          {/*    <span className="menu-title">Tables</span>*/}
          {/*    <i className="menu-arrow"></i>*/}
          {/*  </div>*/}
          {/*  <Collapse in={ this.state.tablesMenuOpen }>*/}
          {/*    <div>*/}
          {/*      <ul className="nav flex-column sub-menu">*/}
          {/*        <li className="nav-item"> <Link className={ this.isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link' } to="/tables/basic-table">Basic Table</Link></li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </Collapse>*/}
          {/*</li>*/}
          {/*<li className={ this.isPathActive('/charts') ? 'nav-item menu-items active' : 'nav-item menu-items' }>*/}
          {/*  <div className={ this.state.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('chartsMenuOpen') } data-toggle="collapse">*/}
          {/*    <span className="menu-icon">*/}
          {/*      <i className="mdi mdi-chart-bar"></i>*/}
          {/*    </span>*/}
          {/*    <span className="menu-title">Charts</span>*/}
          {/*    <i className="menu-arrow"></i>*/}
          {/*  </div>*/}
          {/*  <Collapse in={ this.state.chartsMenuOpen }>*/}
          {/*    <div>*/}
          {/*      <ul className="nav flex-column sub-menu">*/}
          {/*        <li className="nav-item"> <Link className={ this.isPathActive('/charts/chart-js') ? 'nav-link active' : 'nav-link' } to="/charts/chart-js">Chart Js</Link></li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </Collapse>*/}
          {/*</li>*/}
          {/*<li className={ this.isPathActive('/icons') ? 'nav-item menu-items active' : 'nav-item menu-items' }>*/}
          {/*  <div className={ this.state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('iconsMenuOpen') } data-toggle="collapse">*/}
          {/*    <span className="menu-icon">*/}
          {/*      <i className="mdi mdi-contacts"></i>*/}
          {/*    </span>*/}
          {/*    <span className="menu-title">Icons</span>*/}
          {/*    <i className="menu-arrow"></i>*/}
          {/*  </div>*/}
          {/*  <Collapse in={ this.state.iconsMenuOpen }>*/}
          {/*    <div>*/}
          {/*      <ul className="nav flex-column sub-menu">*/}
          {/*        <li className="nav-item"> <Link className={ this.isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link' } to="/icons/mdi">Material</Link></li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </Collapse>*/}
          {/*</li>*/}
          {/*<li className={ this.isPathActive('/user-pages') ? 'nav-item menu-items active' : 'nav-item menu-items' }>*/}
          {/*  <div className={ this.state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('userPagesMenuOpen') } data-toggle="collapse">*/}
          {/*    <span className="menu-icon">*/}
          {/*      <i className="mdi mdi-security"></i>*/}
          {/*    </span>*/}
          {/*    <span className="menu-title">User Pages</span>*/}
          {/*    <i className="menu-arrow"></i>*/}
          {/*  </div>*/}
          {/*  <Collapse in={ this.state.userPagesMenuOpen }>*/}
          {/*    <div>*/}
          {/*      <ul className="nav flex-column sub-menu">*/}
          {/*        <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/login-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/login-1">Login</Link></li>*/}
          {/*        <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/register-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/register-1">Register</Link></li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </Collapse>*/}
          {/*</li>*/}
          {/*<li className="nav-item nav-category">*/}
          {/*  <span className="nav-link">More</span>*/}
          {/*</li>*/}
          {/*<li className={ this.isPathActive('/error-pages') ? 'nav-item menu-items active' : 'nav-item menu-items' }>*/}
          {/*  <div className={ this.state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('errorPagesMenuOpen') } data-toggle="collapse">*/}
          {/*    <span className="menu-icon">*/}
          {/*      <i className="mdi mdi-lock"></i>*/}
          {/*    </span>*/}
          {/*    <span className="menu-title">Error Pages</span>*/}
          {/*    <i className="menu-arrow"></i>*/}
          {/*  </div>*/}
          {/*  <Collapse in={ this.state.errorPagesMenuOpen }>*/}
          {/*    <div>*/}
          {/*      <ul className="nav flex-column sub-menu">*/}
          {/*        <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-404">404</Link></li>*/}
          {/*        <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-500">500</Link></li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </Collapse>*/}
          {/*</li>*/}
          {/*<li className="nav-item menu-items">*/}
          {/*  <a className="nav-link" href="http://bootstrapdash.com/demo/corona-react-free/documentation/documentation.html" rel="noopener noreferrer" target="_blank">*/}
          {/*    <span className="menu-icon">*/}
          {/*      <i className="mdi mdi-file-document-box"></i>*/}
          {/*    </span>*/}
          {/*    <span className="menu-title">Documentation</span>*/}
          {/*  </a>*/}
          {/*</li>*/}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);
