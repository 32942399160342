const Cookies = {
    getCookies: (name) => {
        if (document.cookie.length > 0) {
            let c_start = document.cookie.indexOf(name + "=");
            if (c_start !== -1) {
                c_start = c_start + name.length + 1;
                let c_end = document.cookie.indexOf(";", c_start);
                if (c_end === -1) {
                    c_end = document.cookie.length;
                }
                return decodeURIComponent(document.cookie.substring(c_start, c_end));
            }
        }
        return "";
    }
}

export default Cookies;
