import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import Notification from "./Notification/Notification";
import updateAccessToken from "./outerScripts/updateAccessToken";

class GlobalState extends EventTarget {
  static _instance = null;

  state = {};

  static getInstance() {
    if (GlobalState._instance === null) {
      GlobalState._instance = new GlobalState();
    }

    return GlobalState._instance;
  }

  setState(name, data) {
    this.state[name] = data;

    this.dispatchEvent(new CustomEvent('changeState_' + name, {
      detail: {data}
    }));
  }

  getState(name) {
    return this.state[name];
  }

  pushNotification(notification) {
    this.dispatchEvent(new CustomEvent('pushNotification', {
      detail: {notification}
    }));
  }

  setNavigatedButtons(navigatedButtons) {
    this.dispatchEvent(new CustomEvent('setNavigatedButtons', {
      detail: {navigatedButtons}
    }));
  }

  setNavigationSearch(navigationSearch) {
    this.dispatchEvent(new CustomEvent('setNavigationSearch', {
      detail: {navigationSearch}
    }));
  }
}

class App extends Component {
  state = {
  }

  componentDidMount() {
    this.onRouteChanged();

    updateAccessToken.initialize();

    fetch('https://services.kenguru.ru/auth/User/', {
      method: "GET",
      credentials: "include"
    }).then((response) => response.json())
        .then((data) => {
          GlobalState.getInstance().setState("user", data)
        })
        .catch(e => {
          GlobalState.getInstance().pushNotification({
            text: "Не удалось загрузить информацию о пользователе",
            level: "error",
            time: 4000
          })
        })
  }

  render () {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar/> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar/> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer/> : '';

    return (
      <div className="container-scroller">
        { sidebarComponent }
        <div className="container-fluid page-body-wrapper">
          { navbarComponent }
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes/>
            </div>
            { footerComponent }
          </div>
        </div>
        <Notification></Notification>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.title = "Админ панель";
    GlobalState.getInstance().setNavigatedButtons([]);
    GlobalState.getInstance().setNavigationSearch(undefined);

    console.log("ROUTE CHANGED");
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/login', '/404', '/500'];
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

}

export {GlobalState};

export default withRouter(App);
